import { Injectable } from "@angular/core";
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";

import { AuthenticationService } from "../_services";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        console.log("Auth Guard called");
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log("Inside AuthGuard");
        // if (state.url.indexOf("/") !== -1) {
        //     return true;
        // }
        const currentUser = this.authenticationService.currentUserData;
        const permissions =
            this.authenticationService.currentUserData.loginData.user
                .permissions[0];
        console.log(currentUser);
        console.log("After Current User AuthGuard");
        if (currentUser) {
            //     const routeData = route.data || {};
            //     const stateKey = routeData.stateKey;
            //     if (
            //         stateKey &&
            //         stateKey !== "analytics" &&
            //         routeData.skipRoleCheck &&
            //         currentUser.allowedStates
            //     ) {
            //         const userAccessingInvalidState =
            //             currentUser.allowedStates[stateKey];

            //         console.log(userAccessingInvalidState);
            //         if (userAccessingInvalidState) {
            //             this.router.navigate(["/analytics"]);
            //         }
            //     }
            //     if (currentUser.forcePinChange) {
            //         if (state.url.indexOf("/app-auth/change-password") === -1) {
            //             this.router.navigate(["/app-auth/change-password"], {
            //                 queryParams: { returnUrl: state.url },
            //             });
            //         }
            //     }
            return true;
        } else {
            console.log("inside else");
            this.router.navigate(["/"], {
                queryParams: { returnUrl: state.url },
            });
            return false;
        }
    }
}
