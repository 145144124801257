<div id="wrapper">
    <app-topbar></app-topbar>
    <!-- 
    <app-sidebar
        (settingsButtonClicked)="onSettingsButtonClicked()"
        (mobileMenuButtonClicked)="onToggleMobileMenu()"
        [isCondensed]="isCondensed"
    ></app-sidebar> -->

    <div class="content-page">
        <div class="content">
            <!-- content -->
            <router-outlet></router-outlet>
        </div>

        <!-- footer -->
        <!-- <app-footer></app-footer> -->
    </div>
</div>
