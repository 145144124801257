<!-- ========== Left Sidebar Start ========== -->
<ng-template #contentTemplate>
    <div id="sidebar-menu">
        <ul class="metismenu" id="side-menu" #sideMenu>
            <li class="menu-title">Navigation</li>
            <li *ngIf="canViewAll || isLimited">
                <a
                    routerLink="/analytics"
                    class="waves-effect side-nav-link-ref"
                    routerLinkActive="active"
                >
                    <i class="ri-dashboard-line"></i>
                    <span> Dashboard </span>
                </a>
            </li>
            <li *ngIf="canViewAll || isLimited">
                <a
                    routerLink="/manage-vendors"
                    class="waves-effect side-nav-link-ref"
                    routerLinkActive="active"
                >
                    <i class="ri-store-2-line"></i>
                    <span> Manage Vendors </span>
                </a>
            </li>
            <li *ngIf="canViewAll">
                <a
                    routerLink="/create-buyer"
                    class="waves-effect side-nav-link-ref"
                    routerLinkActive="active"
                >
                    <i class="ri-user-add-line"></i>
                    <span> Manage Users </span>
                </a>
            </li>
            <li *ngIf="canViewAll || isLimited">
                <a
                    routerLink="/buyer-reports"
                    class="waves-effect side-nav-link-ref"
                    routerLinkActive="active"
                >
                    <i class="ri-file-chart-line"></i>
                    <span> Reports </span>
                </a>
            </li>

            <li *ngIf="canViewAll || isLimited || onlyShipping">
                <a
                    routerLink="/shipping-invoices"
                    class="waves-effect side-nav-link-ref"
                    routerLinkActive="active"
                >
                    <i class="ri-ship-2-line"></i>
                    <span>Shipping Invoices</span>
                </a>
            </li>

            <ng-container *ngIf="menuItems && menuItems.length">
                <li *ngFor="let menuItem of menuItems">
                    <ng-container *ngIf="menuItem.children.length">
                        <a
                            href="javascript: void(0);"
                            class="waves-effect"
                            aria-expanded="false"
                            (click)="menuItem.expanded = !menuItem.expanded"
                        >
                            <i [class]="menuItem.icon"></i>
                            <span> {{ menuItem.stateDisplayName }}</span>
                            <span class="menu-arrow"></span>
                        </a>
                        <div class="collapse show">
                            <ul class="nav-second-level">
                                <ng-container
                                    *ngFor="
                                        let childMenuItem of menuItem.children
                                    "
                                >
                                    <li *ngIf="childMenuItem.isAccessible">
                                        <a
                                            [routerLink]="
                                                childMenuItem.routeUrl
                                            "
                                            class="waves-effect side-nav-link-ref"
                                            routerLinkActive="active"
                                        >
                                            <i [class]="childMenuItem.icon"></i>
                                            <span>
                                                {{
                                                    childMenuItem.stateDisplayName
                                                }}
                                            </span>
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!menuItem.children.length">
                        <a
                            [routerLink]="menuItem.routeUrl"
                            class="waves-effect side-nav-link-ref"
                            routerLinkActive="active"
                        >
                            <i [class]="menuItem.icon"></i>
                            <span> {{ menuItem.stateDisplayName }} </span>
                        </a>
                    </ng-container>
                </li>
            </ng-container>
            <!-- <li>
                <a (click)="logout()" class="waves-effect side-nav-link-ref">
                    <i class="ri-logout-box-line"></i>
                    <span> Logout </span>
                </a>
            </li> -->
        </ul>
    </div>
    <!-- End Sidebar -->
    <div class="clearfix"></div>

    <!--- Sidemenu -->

    <!-- End Sidebar -->

    <div class="clearfix"></div>
</ng-template>

<div class="left-side-menu">
    <div class="logo-box">
        <!-- <ul class="logo logo-light text-center">
            <li>
                <button
                    class="button-menu-mobile waves-effect waves-light"
                    (click)="toggleMobileMenu($event)"
                >
                    <i class="fe-menu"></i>
                </button>
            </li>
        </ul> -->

        <!-- <ul class="list-unstyled topnav-menu-left m-0">
            <li>
                <button
                    class="button-menu-mobile waves-effect waves-light"
                    (click)="toggleMobileMenu($event)"
                >
                    <i class="fe-menu"></i>
                </button>
            </li>
        </ul> -->

        <span class="logo logo-light text-center">
            <span class="logo-sm">
                <button
                    class="button-menu-mobile waves-effect waves-light btn-transparent"
                    (click)="toggleMobileMenu($event)"
                >
                    <i class="ri-menu-line"></i>
                </button>
            </span>
            <span class="logo-lg">
                <button
                    class="button-menu-mobile waves-effect waves-light btn-transparent"
                    (click)="toggleMobileMenu($event)"
                >
                    <i class="ri-menu-line"></i>
                </button>
            </span>
        </span>
        <!-- <a [routerLink]="['/home']" class="">
            <span class="logo-sm">
                <img src="assets/images/payex.png" alt="" height="40">
            </span>
            <span class="logo-lg">
                <img src="assets/images/payex.png" alt="" height="40">
            </span>
        </a> -->
    </div>
    <div class="slimscroll-menu" appSlimScroll *ngIf="!isCondensed">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div class="slimscroll-menu" *ngIf="isCondensed">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
</div>
<!-- Left Sidebar End -->
